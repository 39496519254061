import styled from 'styled-components'
import { Accordion } from 'react-bootstrap'

export const PageTitle = styled.h1`
    font-size: 1.5rem;
`

export const PolicyTitle = styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
`

export const PolicyPara = styled.p`
    text-align: justify;
`

export const FAq = styled(Accordion)`
    background: ${({theme}) => theme.accordion.body};
    border: 0;
`
export const FAQItem = styled(FAq.Item)`
    border: 0;

    .accordion-button{
        background: ${({theme}) => theme.accordion.headerBg} !important;
        color: ${({theme}) => theme.accordion.text};
    }
`

export const FAQTitle = styled(FAq.Header)`
    background: ${({theme}) => theme.accordion.headerBg} !important;
`

export const FAQBody = styled(FAq.Body)`
    background: ${({theme}) => theme.accordion.body};
`

export const HighlightLink = styled.span`
    background: ${({theme}) => theme.accent.black.elev2};
    color: ${({theme}) => theme.text};
    border-radius: 5px;
    padding-right: 0.8em;
    padding-left: 0.8em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;

    small{
        opacity: 0.5;
    }
`

export const HightlightText = styled.span`
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: yellow;
`