import React from 'react'
import { Container, Col, Row, Alert } from 'react-bootstrap'
import {PageTitle, PolicyTitle, FAq, FAQItem, FAQTitle, FAQBody} from '../../styles/pages/policyPage.styles'
import { Icon } from '@iconify/react';
    

const Shipping = () => {
    return(
        <Container fluid>
            <Row className="mx-auto my-5">
                <Col sm="12" md="11">
                    <PageTitle>Shipping and Delivery</PageTitle>
                    <small>Policy</small>
                </Col>
            </Row>
            <Row className="mx-auto mt-3">
                <Col sm={12} md={8}>
                    <Row md={2} className="gy-5">
                        <Col>
                            <PolicyTitle>Methods of shipping</PolicyTitle>
                            <p>Attars and Perfumes, Candles and other flammable goods are categorized as Dangerous Goods (DG), so the Air way shippment is restricted. hence we ship it via <Icon icon="emojione-v1:delivery-truck" width={18} height={18} /> Road Ways (Surface)</p>
                        </Col>
                        <Col>
                            <PolicyTitle>Prices of shipment</PolicyTitle>
                            <p>We are using <Icon icon="heroicons-solid:currency-rupee" /> flat price rate for all the 4 zones.</p>
                            <p>
                                For within chennai city, only Rs.40<br/>
                                For within Tamil Nadu, only Rs.50<br/>
                                All other area will be , only Rs.60 Charged for shipping.
                            </p>
                        </Col>
                        <Col>
                            <PolicyTitle>Shipping Carriers</PolicyTitle>
                            <p>Only few courier services providing  Surface mode of shipment. As of now we using Xpressbees, Delhivery, Ecom Express for delivery services.</p>
                        </Col>
                        <Col>
                            <PolicyTitle>Shipment Handling Time</PolicyTitle>
                            <p>We usually process the order and ship it by next working day from the date of order.</p>
                            <p>Regional Festivals and Holidays may impact the pickup service and cause delay in pickup.</p>
                        </Col>
                        <Col>
                            <PolicyTitle>Delivery Attempts</PolicyTitle>
                            <p>There is only 3 attempts for delivery. after that the package automatically marked as RTO by courier partner.</p>
                            <p>Please always give your active mobile number, it will help our courier partner to contact you for delivery and Delhivery service providing OTP verified delivery so the active and correct mobile number is necessary</p>
                            <Alert variant="dark"><Icon icon="noto:spiral-calendar" /> Check the estimated delivery date before placing order and if you won't be there while delivery please inform to your Roommates / Family / Neighbour to receive package behalf of you.!</Alert>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <PolicyTitle>FAQ</PolicyTitle>
                    <FAq defaultActiveKey="0">
                        <FAQItem eventKey="0">
                            <FAQTitle>Can we set preferred time for delivery?</FAQTitle>
                            <FAQBody>
                            No,.. Thats impossible. None of the courier services providing Preferred time delivery. So, Please check estimated delivery time before placing order and plan accordingly!!
                            </FAQBody>
                        </FAQItem>
                        <FAQItem eventKey="1">
                            <FAQTitle>Why Bluedart not available?</FAQTitle>
                            <FAQBody>
                            We requested them for integration but, they currently not shipping any products marked as Dangerous Goods (DG). Sorry! <Icon icon="noto:person-shrugging-light-skin-tone" width="18" height="18" />
                            </FAQBody>
                        </FAQItem>
                        <FAQItem eventKey="2">
                            <FAQTitle>Why my package takes so long time for delivery?</FAQTitle>
                            <FAQBody>
                            <p>All of our shipments are picked up from our store in Chennai and we don't have any regional warehouse or store branch. So, please consider the timing between pickup and delivery distance.</p>
                            <Alert variant="info">
                            Amazon and Flipkart having their own regional warehouse and logistics for shipping. that's the reason for faster delivery and reduced shipping cost.
                            </Alert>
                            </FAQBody>
                        </FAQItem>
                        <FAQItem eventKey="3">
                            <FAQTitle>Why extra charges for COD orders?</FAQTitle>
                            <FAQBody>
                            Our courier partners adding extra charges for each shipment for cash handling. and the charges would be Rs.45 or 2% which one is heigher.
                            </FAQBody>
                        </FAQItem>
                        <FAQItem eventKey="4">
                            <FAQTitle>Can i change contact mobile number?</FAQTitle>
                            <FAQBody>
                            <p>if you want to change mobile number or address, please report before the courier booking, then only we can update it to order.</p>
                            </FAQBody>
                        </FAQItem>
                    </FAq>
                </Col>
            </Row>
        </Container>
    )
}

export default Shipping